import { sortBy } from "lodash";
import { observable, values } from "mobx";

const _dispoUsrIds = {
    "4zaQRcnTGyMBlkxDFGq9dKi5qaJ2": true,
    hDmXjdEZjAcxVz7f3eHnfHc4vG02: true,
    PccNBhxKeIfRGGutruqn4Ir0eEG3: true,
};
const _billingUsrIds = {
    ALu7StQrfkgMJsnwDlm5byTBKI33: true,
    "8nuGE1hHGzUrqqOLXS62E1n2DNB2": true,
};

const _canCollectMaterialsUsrIds = {
    Z6V74sR1AEb74ew1j7WHjgGrBJJ2: true,
    E1Ch2p76juSDsZVjRzIPXNqecLX2: true,
};

export default observable({
    get sortedUsrs() {
        const _usrs = values(this).filter((it) => it.id);

        return sortBy(_usrs, ["lname", "fname", "email"]);
    },

    get sortedUsrIds() {
        return this.sortedUsrs.map((it) => it.id);
    },

    get sortedDispoUsrIds() {
        return this.sortedUsrs
            .filter((it) => _dispoUsrIds[it.id])
            .map((it) => it.id);
        //return this.sortedUsrs.filter((it) => it.rights & 1).map((it) => it.id);
    },
    get billingDispoUsrIds() {
        return this.sortedUsrs
            .filter((it) => _billingUsrIds[it.id])
            .map((it) => it.id);
        //return this.sortedUsrs.filter((it) => it.rights & 2).map((it) => it.id);
    },
    get canCollectMaterialsUsrIds() {
        return this.sortedUsrs
            .filter((it) => _canCollectMaterialsUsrIds[it.id] || it.rights & 4)
            .map((it) => it.id);
        //return this.sortedUsrs.filter((it) => it.rights & 1).map((it) => it.id);
    },
});
