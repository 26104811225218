import { get } from "lodash";
import { computed, makeObservable, observable } from "mobx";
import { CmdTools } from "./cmd";

export class CmdToolAction {
    _srvData = {};

    constructor(srvData) {
        this._srvData = srvData;

        makeObservable(this, {
            _srvData: observable,

            id: computed,

            usrId: computed,
            ts: computed,
            typ: computed,
            com: computed,

            garduri: computed,
            talpi: computed,
            barci: computed,
            lumini: computed,
            semne: computed,
            placiOtel: computed,
        });
    }

    get id() {
        return get(this, ["_srvData", "id"], "");
    }

    get ts() {
        return get(this, ["_srvData", "ts"], 0);
    }
    get usrId() {
        return get(this, ["_srvData", "u"], 0);
    }
    get typ() {
        return get(this, ["_srvData", "typ"], "");
    }
    get com() {
        return get(this, ["_srvData", "com"], "");
    }

    get garduri() {
        return get(this, ["_srvData", CmdTools.GARDURI.key], 0);
    }
    get talpi() {
        return get(this, ["_srvData", CmdTools.TALPI.key], 0);
    }
    get barci() {
        return get(this, ["_srvData", CmdTools.BARCI.key], 0);
    }
    get lumini() {
        return get(this, ["_srvData", CmdTools.LUMINI.key], 0);
    }
    get semne() {
        return get(this, ["_srvData", CmdTools.SEMNE.key], 0);
    }
    get placiOtel() {
        return get(this, ["_srvData", CmdTools.PLACI_OTEL.key], 0);
    }
}
